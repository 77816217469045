<template>
  <b-container fluid class="footer">
    <b-row class="py-4 px-5 footer__row">
      <b-col class="footer__col-left">
        <p class="text-light">© Copyright 2024. Offerte.nl</p>
      </b-col>
      <b-col class="footer__col-right">
        <p class="text-light"><span class="cursor-pointer underline" v-b-modal.PrivacyPolicy>
            Privacyverklaring
          </span> &
          <span class="cursor-pointer underline" v-b-modal.termsConditions>
            Algemene voorwaarden
          </span>
        </p>
      </b-col>

    </b-row>

    <div>
      <b-modal id="PrivacyPolicy" centered title="Privacy statement" ok-only size="lg" ok-variant="success" scrollable>

        <div class="px-3">
          <p>
            Wij zijn Duurzame Huiseigenaren (onderdeel van Offerte B.V.). Wij respecteren jouw privacy en persoonlijke
            levenssfeer, maar soms hebben wij persoonlijke gegevens van je nodig. In deze verklaring lees je welke
            gegevens wij van jou, als aanvrager van een offerte via ons, gebruiken en hoe wij deze gegevens opslaan,
            beschermen en verwerken. Deze privacy policy is van toepassing op onze website www.duurzame-huiseigenaren.nl
            (de Website) en de diensten die wij aanbieden (de Diensten). Wij behandelen je persoonlijke gegevens conform
            de Algemene Verordening Gegevensbescherming (de Relevante Wetgeving).
          </p>

          <div class="content_box">
            <h4>Jonger dan 16 jaar</h4>
            <p>
              Als je jonger dan 16 jaar bent, dan heb je toestemming van je ouders of wettelijke voogd nodig om onze
              Website en Diensten te gebruiken.
            </p>
          </div>
          <div class="content_box">
            <h4>Persoongegevens</h4>
            <p>
              Om onze Website en Diensten aan te kunnen bieden, verwerken wij persoonsgegevens. Met het begrip
              “persoonsgegeven” wordt informatie bedoeld waarmee je als persoon geïdentificeerd kan worden. Deze
              definitie
              is in overeenstemming met de Relevante Wetgeving.
            </p>
          </div>
          <div class="content_box">
            <h4>Grondslagen</h4>
            <p>
              We kunnen je gegevens op verschillende momenten bewaren en verwerken, zoals wanneer je onze Website
              bezoekt,
              gebruik maakt van onze Diensten en/of als je contact met ons opneemt. Wij mogen je gegevens verwerken om
              de
              overeenkomst tussen jou en ons uit te voeren. Voor de verwerking van een aantal gegevens hebben we een
              gerechtvaardigd belang.
            </p>
            <p>Verder zullen we je gegevens niet zonder je toestemming verwerken, tenzij we daartoe wettelijk verplicht
              zijn.</p>
          </div>
          <div class="content_box">
            <h4>
              Welke gegevens verzamelen wij en wat doen we ermee?
            </h4>
            <p>Om gebruik te maken van onze Website en Diensten kunnen wij de volgende gegevens van je nodig hebben:
            </p>
            <ul>
              <li>jouw naam;</li>
              <li>jouw adresgegevens;</li>
              <li>jouw woonplaats;</li>
              <li>jouw telefoonnummer;</li>
              <li>jouw e-mailadres;</li>
              <li>jouw bedrijfsgegevens</li>
              <li>jouw IP-adres.</li>
            </ul>
            <p>
              Wij kunnen ook informatie van je verzamelen die niet persoonlijk is als je onze Website gebruikt, zoals:
            </p>
            <ul>
              <li>de naam van de browser die je gebruikt;</li>
              <li>het besturingssysteem dat je gebruikt;</li>
              <li>de service provider waarmee je internettoegang heeft;</li>
              <li>het soort device waarmee je verbinding maakt met de Website.</li>
            </ul>
          </div>
          <div class="content_box">
            <h4>
              Offerteaanvraag
            </h4>
            <p>
              De door jou ingevulde gegevens in de offerteaanvraag verwerken wij om onze Dienst aan je te kunnen
              uitvoeren. Wij zenden je offerteaanvraag aan maximaal vijf relevante bij ons aangesloten dienstverleners
              door die de door jouw gezochte dienst aanbieden. Dit doen wij met het doel dat zij aan jou een offerte
              kunnen uitbrengen.
            </p>
          </div>
          <div class="content_box">
            <h4>
              Marktonderzoek

            </h4>
            <p>
              Het kan voorkomen dat we je vragen om deel te nemen aan een marktonderzoek. Wij zullen je gegevens dan
              gebruiken voor het marktonderzoek. Wij gebruiken deze statistische gegevens anoniem. Daarnaast worden de
              antwoorden niet aan je e-mailadres gekoppeld.

            </p>
          </div>

          <div class="content_box">
            <h4>
              Nieuwsbrief

            </h4>
            <p>
              Duurzame huiseigenaren verstuurt nieuwsbrieven aan offerteaanvragers. Zo blijf je op de hoogte van
              aanbiedingen, (kortings)acties en ander nieuws. Je contactgegevens gebruiken wij om je deze nieuwsbrief
              aan te bieden. Hiervoor hebben wij een gerechtvaardigd belang. Bij iedere nieuwsbrief zal een
              afmeldmogelijkheid worden opgenomen.
            </p>
            <p>
              Wij zullen je gegevens alleen voor bovenstaande doelen gebruiken, of voor een doel dat hiermee nauw
              samenhangt. Zo worden je gegevens dus nooit op een onverwachtse manier door ons gebruikt.
            </p>
          </div>
          <div class="content_box">
            <h4>
              Beveiliging
            </h4>
            <p>
              We doen er alles aan om je persoonlijke gegevens te beschermen tegen verlies, vernietiging, gebruik,
              wijziging of verspreiding van je persoonlijke gegevens door onbevoegden. Hierdoor kunnen degenen die niets
              met je gegevens te maken hebben, er niet bij. Wij doen dit door middel van de volgende maatregelen:
            </p>
            <ul>
              <li>encryptie (versleuteling) van digitale bestanden die persoonsgegevens bevatten;</li>
              <li>beveiliging van netwerkverbindingen met Secure Socket Layer (SSL), of een vergelijkbare technologie;
              </li>
              <li>de toegang tot de gegevens is beperkt tot de personen die de gegevens nodig hebben;</li>
              <li>geheimhoudingsverklaringen van werknemers;</li>
              <li>back-up van de gegevens.</li>

            </ul>
          </div>
          <div class="content_box">
            <h4>
              Bewaartermijn
            </h4>
            <p>
              Wij zullen je persoonlijke gegevens niet langer bewaren dan we ze nodig hebben voor de doelen die wij
              eerder omschreven.
            </p>
            <h5> Met wie delen wij je persoonlijke gegevens? </h5>
            <div class="inner_box">
              <p class="inner_box-heading">
                Verwerkers
              </p>
              <p>
                Het komt voor dat andere partijen je gegevens van ons krijgen, bijvoorbeeld: onze hosting partij. Wij
                komen met deze partijen in een verwerkersovereenkomst overeen dat zij zorgvuldig met je gegevens
                omgaan
                en zij krijgen alleen de gegevens die noodzakelijk zijn om hun dienst te verlenen. Deze partijen
                gebruiken je gegevens alleen in overeenstemming met de instructies die wij geven en niet voor eigen
                doeleinden. Deze partijen zijn “verwerkers” in de zin van de Relevante Wetgeving.
              </p>
            </div>
            <div class="inner_box">
              <p class="inner_box-heading">
                Derden
              </p>
              <p>
                Om onze Dienst aan je te kunnen leveren, delen we je gegevens in de offerteaanvraag met andere partijen,
                die geen verwerker zijn: de dienstverleners die volgens ons selectieproces jou een offerte kunnen
                uitbrengen. Met deze partijen spreken wij af dat ze je gegevens zorgvuldig behandelen. Zij zullen de
                gegevens alleen gebruiken in overeenstemming met het doel waarvoor wij de gegevens van je hebben
                gekregen: het uitbrengen van een offerte aan jou.
              </p>
            </div>
          </div>

          <div class="content_box">
            <h4>
              Doorgifte
            </h4>
            <p>
              Wij zullen je gegevens alleen binnen de Europese Unie verwerken. Buiten de Europese Unie verwerken wij
              alleen je gegevens, als dat land een passend beschermingsniveau biedt voor je gegevens.
            </p>
          </div>
          <div class="content_box">
            <h4>
              Links
            </h4>
            <p>
              Op onze Website kunnen links naar andere websites staan. Wij zijn niet verantwoordelijk voor de inhoud
              of de privacy bescherming van deze websites. We adviseren je dan ook om altijd de privacy policy van de
              betreffende website te lezen. </p>
          </div>
          <div class="content_box">
            <h4>
              Cookie
            </h4>
            <p>Een cookie is een klein tekstbestand dat via de server van een website naar de browser wordt gestuurd. De
              browser slaat het bestand vervolgens op, op je apparaat. Je apparaat krijgt een uniek nummer, waarmee onze
              Website het apparaat later weer herkent. We kunnen cookies gebruiken om je ervaring op onze Website te
              verbeteren. Cookies zorgen er daarnaast o.a. voor dat de Website snel is, je onze Website veilig kunt
              bezoeken en wij fouten op onze Website kunnen opsporen.
            </p>
            <p>Je kunt cookies altijd zelf via de browserinstellingen verwijderen of uitschakelen. Er worden dan geen
              cookies meer opgeslagen als je onze Website bezoekt. Maar let wel op: zonder cookies werkt onze Website
              mogelijk minder goed.
            </p>
          </div>
          <div class="content_box">
            <h4>
              Wijziging van de privacy policy
            </h4>
            <p>Wij kunnen deze privacy policy aanpassen. Als wij de privacy policy in belangrijke mate veranderen zullen
              wij een notificatie op onze Website plaatsen samen met de nieuwe privacy policy. Wij zullen gebruikers die
              met hun e-mailadres bij ons geregistreerd staan op de hoogte brengen bij wijzigingen. Als je niet
              geregistreerd staat als gebruiker raden wij je aan de Website en deze verklaring regelmatig te raadplegen.
            </p>
          </div>
          <div class="content_box">
            <h4>
              Jouw rechten
            </h4>
            <p>De gegevens die we van je verzamelen zijn persoonlijk. Je hebt daarom de volgende rechten:
            </p>
            <ul>
              <li>je mag ons vragen om inzage in jouw gegevens;</li>
              <li>je mag ons vragen om correctie, beperking of wissing van uw gegevens;</li>
              <li>je mag ons vragen om een kopie van jouw gegevens;</li>
              <li>je kunt bezwaar maken tegen de verwerking van jouw gegevens;</li>
              <li>je kunt een klacht indienen bij de Autoriteit Persoonsgegevens, als je denkt dat wij je gegevens
                onrechtmatig verwerken;</li>
              <li>je mag je toestemming om je gegevens te verwerken altijd weer intrekken. Vanaf het moment dat je de
                toestemming intrekt mogen wij dan geen gegevens meer van je verwerken.</li>
            </ul>
            <p>
              Bij andere vragen of opmerkingen over onze privacy policy kun je contact met ons opnemen via onderstaande
              gegevens.
            </p>
          </div>
          <div class="content_box">
            <h4>
              Contact
            </h4>
            <p>Duurzame Huiseigenaren.nl, onderdeel van Offerte B.V.<br />
              Westeinde 12<br />
              1017 ZN Amsterdam<br />
              KvK-nummer: 63835746<br />
              Telefoonnummer: 020 895 1709<br />
              E-mailadres: info@duurzame-huiseigenaren.nl<br />
            </p>
          </div>
        </div>
      </b-modal>
    </div>
    <div>
      <b-modal id="termsConditions" centered title="Algemene voorwaarden" ok-only size="lg" ok-variant="success"
        scrollable>

        <div class="px-3">
          <p>
            Wij willen je graag een goede dienstverlening bieden en vinden het
            daarom belangrijk dat het duidelijk is wat je van ons kunt verwachten.
            Hieronder tref je onze algemene voorwaarden die op onze dienstverlening
            aan jou van toepassing zijn.
          </p>
          <p>
            Heb je na het lezen van de algemene voorwaarden nog vragen? Neem dan
            gerust contact met ons op via het e-mailadres <a href="mailto:info@duurzame-huiseigenaren.nl">
              info@duurzame-huiseigenaren.nl</a> of het
            telefoonnummer <a href="tel:020 895
              1709">020 895
              1709</a>

          </p>

          <div class="content_box">
            <h4>Definities</h4>
            <p>
            <ul>
              <li>
                Duurzame Huiseigenaren is onderdeel van Offerte: de besloten vennootschap met beperkte aansprakelijkheid
                Offerte B.V., met KvK-nummer 63835746
              </li>
              <li>
                Partner(s): dienstverleners – rechtspersonen en natuurlijke personen –
                die met Offerte een overeenkomst sluiten met als doel van Offerte
                Offerteaanvragen te ontvangen.
              </li>
              <li>
                Jij: de aanvrager – rechtspersoon of natuurlijke persoon – die een
                offerteaanvraag invult via de Website van Offerte.
              </li>
              <li>
                Offerteaanvraag: een door een bezoeker van de Website ingezonden verzoek
                om via de Website van Offerte in contact te komen met Partners
              </li>
              <li>
                Website: alle websites die Offerte exploiteert voor haar
                dienstverlening.
              </li>


            </ul>
            </p>
          </div>
          <div class="content_box">
            <h4>Toepasselijkheid van deze algemene voorwaarden</h4>
            <p>
            <ul>
              <li>Deze algemene voorwaarden zijn van toepassing op de Offerteaanvraag die
                Offerte je via de Website biedt. Als je een offerteaanvraag plaatst, dan
                gelden deze algemene voorwaarden tussen Offerte en jou.
              </li>
              <li>
                Van deze algemene voorwaarden kan slechts worden afgeweken, als je dit
                voorafgaand aan je Offerteaanvraag uitdrukkelijk schriftelijk met
                Offerte bent overeengekomen.
              </li>
              <li>
                Offerte heeft het recht deze algemene voorwaarden te wijzigingen. Jij
                stemt ermee in dat steeds de laatste versie van deze algemene
                voorwaarden gelden tussen Offerte en jou. De laatste versie van deze
                algemene voorwaarden kun je vinden op: <a href="https://offerte.nl/algemene-voorwaarden">
                  https://offerte.nl/algemene-voorwaarden.</a>

              </li>
            </ul>
            </p>
          </div>
          <div class="content_box">
            <h4>Dienstverlening van Offerte</h4>
            <p>
            <ul>
              <li>
                Via de Website van Offerte kun je gratis een offerteaanvraag doen voor
                een door jou opgegeven dienst.
              </li>
              <li>
                Offerte stuurt je offerteaanvraag aan maximaal vijf relevante Partners
                die de door jou gezochte dienst aanbieden.
              </li>
              <li>
                Offerte brengt met haar dienstverlening vraag en aanbod bij elkaar.
                Offerte is geen partij bij de eventuele overeenkomst die jij en een
                Partner met elkaar sluiten.
              </li>
            </ul>
            </p>
          </div>
          <div class="content_box">
            <h4>
              Offerteaanvraag
            </h4>
            <ul>
              <li>Als Offerte je offerteaanvraag ontvangt, beoordeelt zij deze aanvraag.
                Als de aanvraag compleet is, stuurt zij deze door aan de Partners die
                Offerte op basis van de gegevens uit jouw offerteaanvraag voor je heeft
                geselecteerd.</li>
              <li>Offerte garandeert niet dat de Partners met je contact opnemen.</li>
            </ul>
          </div>
          <div class="content_box">
            <h4>
              Jouw gegevens
            </h4>
            <p>
              Offerte beschermt jouw persoonsgegevens in overeenstemming met de Algemene
              Verordening Gegevensbescherming (AVG). Voor meer informatie hierover
              verwijst Offerte naar haar Privacy Policy voor de Aanvrager.
            </p>
          </div>


          <div class="content_box">
            <h4>
              Klachten
            </h4>
            <p>
            <ul>
              <li>
                Offerte wil graag dat haar dienstverlening aansluit bij je
                verwachtingen. Als je een klacht hebt over de dienstverlening van
                Offerte, verzoekt Offerte dit te melden via het e-mailadres
                info@offerte.nl.
              </li>
              <li>
                Klachten worden in behandeling genomen als zij schriftelijk worden
                ingediend via het vermelde e-mailadres. De klacht dient een
                beschrijving van de situatie en eventuele bewijsstukken te bevatten.
              </li>
              <li>
                Offerte streeft ernaar binnen vijf werkdagen de klacht van reactie te
                voorzien.
              </li>
            </ul>

            </p>
          </div>

          <div class="content_box">
            <h4>
              Aansprakelijkheid
            </h4>
            <p>
            <ul>
              <li>Offerte is alleen aansprakelijk voor directe schade van jou, die
                rechtstreeks en uitsluitend het gevolg is van een tekortkoming van
                Offerte.</li>
              <li>Offerte is niet aansprakelijk voor schade die is ontstaan doordat zij is
                uitgegaan van door de jou verstrekte onjuiste of onvolledige gegevens.</li>
              <li>Offerte is niet aansprakelijk voor schade die is ontstaan door handelen
                en/of nalaten van een Partner.</li>
              <li>
                De aansprakelijkheid van Offerte is in ieder geval beperkt tot het
                bedrag dat haar verzekeraar in dat geval uitkeert.
              </li>
              <li>De in dit artikel opgenomen beperkingen van de aansprakelijkheid gelden
                niet als de schade te wijten is aan opzet of grove schuld van Offerte.</li>
            </ul>
            </p>
          </div>


          <div class="content_box">
            <h4>
              Nietigheid
            </h4>
            <p>
              Als een bepaling van deze voorwaarden nietig of vernietigbaar is,
              verandert dat niets aan de geldigheid van de rest van de overeenkomst. Het
              nietige of vernietigde deel wordt vervangen door een bepaling die zoveel
              mogelijk de inhoud van de nietige bepaling volgt.
            </p>
          </div>
          <div class="content_box">
            <h4>
              Toepasselijk recht
            </h4>
            <p>
              Op overeenkomsten die aan deze algemene voorwaarden zijn onderworpen is
              Nederlands recht van toepassing.
            </p>
          </div>

          <div class="content_box">
            <h4>
              Bevoegde rechter
            </h4>
            <p>
              Geschillen tussen Partijen omtrent overeenkomsten die aan deze algemene
              voorwaarden zijn onderworpen, behoren uitsluitend tot de bevoegdheid van
              Rechtbank Amsterdam.
            </p>
          </div>
        </div>
      </b-modal>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #171734;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin-bottom: 0;
    }
  }

  &__col-left {
    text-align: left;
  }

  &__col-right {
    text-align: right;
  }

  @media (max-width:992px) {
    &__row {
      flex-direction: column;
      gap: 20px;
    }

    &__col-left {
      text-align: center;
    }

    &__col-right {
      text-align: center;
    }
  }
}
</style>
