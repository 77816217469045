<template>
  <b-container class="main-cont mb-3">
    <div v-if="firstQuestion.show">
      <b-form class="input__form" @submit.prevent="onSubmit">
        <BackButton @back="goBack" />
        <p class="mb-0 vraag">Vraag {{ step }} van 6</p>
        <h2 class="title">Wat is jouw adres?</h2>
        <div class="row my-4 mx-0">
          <div class="col-lg-5 p-0 mb-3 mb-lg-0">
            <b-form-group
              id="Postcode"
              class="input__form-label"
              label="Postcode"
              label-for="Postcode"
            >
              <b-form-input
                v-model="firstQuestion.zip"
                placeholder="3822 VJ"
                class="input__form-field text-uppercase"
                :class="{
                  'is-valid': validationCheck && firstQuestion.zip !== '',
                  'is-invalid': !validationCheck,
                }"
                type="text"
                required
                @input="validate('zip')"
              ></b-form-input>
              <b-form-invalid-feedback v-if="!validationCheck"
                >Moet minimaal 4 numerieke tekens en 2 tekens bevatten. postcode
                niet correct</b-form-invalid-feedback
              >
            </b-form-group>
            <div class="" v-if="setAddress">
              <p show dismissible class="text-danger">adres is niet correct</p>
            </div>
          </div>
          <div class="col-lg-5 p-0">
            <b-form-group
              id="Huisnummer"
              class="input__form-label"
              label="Huisnummer"
              label-for="Postcode"
            >
              <b-form-input
                v-model="firstQuestion.house_number"
                :class="{
                  'is-valid':
                    invalidAddress && firstQuestion.house_number !== '',
                  'is-invalid': !invalidAddress,
                }"
                @input="validate('house')"
                required
                placeholder="6"
                class="input__form-field text-uppercase"
                type="text"
              ></b-form-input>
              <b-form-invalid-feedback v-if="!invalidAddress"
                >Enter a valid House Number</b-form-invalid-feedback
              >
            </b-form-group>
          </div>
          <div
            v-if="loader"
            class="col-lg-1 mt-4 p-0 d-flex justify-content-center align-items-center"
          >
            <b-spinner small variant="primary" />
          </div>
        </div>
        <div class="mt-2">
          <b-button class="input__form-btn" type="submit" :disabled="loader">
            Ophalen woninggegevens
            <span
              ><svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46973 3.53029L10.9395 9.00004L5.46973 14.4698L6.53023 15.5303L13.0605 9.00004L6.53023 2.46979L5.46973 3.53029Z"
                  fill="white"
                />
              </svg> </span
          ></b-button>
        </div>
      </b-form>
    </div>
    <div v-else>
      <div class="input__form">
        <h2 class="title">Jouw adres</h2>
        <div class="row my-4 mx-0">
          <div class="col-12 p-0">
            <p class="input__form-label">
              {{ this.address.street }}
              {{ this.address.streetNumber }}{{ this.address.premise }},

              {{ this.address.postalCode }}
              in
              {{ this.address.settlement }}
            </p>
            <GoogleMapComponent />
          </div>
        </div>
        <div>
          <b-button class="input__form-btn" @click="goToNextStep">
            Volgende
            <span
              ><svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46973 3.53029L10.9395 9.00004L5.46973 14.4698L6.53023 15.5303L13.0605 9.00004L6.53023 2.46979L5.46973 3.53029Z"
                  fill="white"
                />
              </svg>
            </span>
          </b-button>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import loader from "sass-loader";
import GoogleMapComponent from "../../GoogleMapComponent.vue";
import { mapActions, mapGetters } from "vuex";
import BackButton from "../../BackButton.vue";

export default {
  name: "Question1",
  components: { GoogleMapComponent, BackButton },
  props: {
    step: {
      type: Number,
    },
    firstQuestion: {
      type: Object,
      required: true,
    },
    setAddress: {
      type: Boolean,
    },
  },
  data() {
    return {
      loader: false,
      zipRegex: /^([0-9]{4}\s?[a-zA-Z]{2})$/,
      // houseRegex: /^[0-9]+$/,
      // houseRegex: /^[a-zA-Z]{1}[0-9]+$|^[0-9]+[a-zA-Z]{1}$/,
      houseRegex: /^[0-9A-Za-z\s\-]+$/,
      validationCheck: true,
      invalidAddress: true,
    };
  },
  computed: {
    ...mapGetters(["lat", "lng", "getErrorResponses", "address"]),
  },
  methods: {
    ...mapActions(["getLocation"]),
    validate(key) {
      if (key == "zip") {
        this.validationCheck = this.zipRegex.test(this.firstQuestion.zip);
      } else {
        this.invalidAddress = this.houseRegex.test(
          this.firstQuestion.house_number
        );
      }
    },
    onSubmit() {
      const isFormValid = this.validationCheck && this.invalidAddress;
      if (isFormValid) {
        this.loader = true;
        const houseNumberWithoutPunctuation =
          this.firstQuestion.house_number.replace(/[\s-]/g, "");
        this.getLocation({
          zip: this.firstQuestion.zip,
          house_number: houseNumberWithoutPunctuation,
          onSuccess: () => {
            this.firstQuestion.show = false;
            this.loader = false;
          },
          onError: () => {
            this.loader = false;
            this.setAddress = true;
            setTimeout(() => {
              this.setAddress = false;
            }, 5000);
          },
        });
      }
    },
    goToNextStep() {
      this.$emit("nextStep", this.step + 1);
    },
    goBack() {
      this.$emit("goBack", this.step - 1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
