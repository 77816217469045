<template>
  <b-container class="main-cont mb-3">
    <b-form class="input__form">
      <BackButton @back="goBack" />
      <p class="mb-0 vraag">Vraag {{ step }} van 6</p>
      <h2 class="title">Soort aanvraag?</h2>
      <div class="row my-4 mx-0">
        <div class="col-6 col-sm-4 p-0" v-for="index in values" :key="index">
          <div :class="{ 'input__form-div-selected': selected == index, 'input__form-div': selected != index }"
            @click="updateDiv(index)">

            <div v-if="index == '5008'">
              <svg width="115" height="107" viewBox="0 0 115 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M114.343 48.0708L106.187 20.0541V4.3125C106.187 3.23091 105.757 2.19363 104.992 1.42883C104.228 0.664033 103.19 0.234375 102.109 0.234375H89.8744C88.7928 0.234375 87.7555 0.664033 86.9907 1.42883C86.2259 2.19363 85.7963 3.23091 85.7963 4.3125V16.5469H12.39C11.4747 16.513 10.5746 16.7883 9.83482 17.3284C9.09502 17.8684 8.55856 18.6418 8.31188 19.5239L0.155633 48.0708C-0.0150027 48.6726 -0.0449043 49.3056 0.0682407 49.9208C0.181386 50.5361 0.434545 51.117 0.808133 51.6188C1.20419 52.1479 1.72293 52.5729 2.31968 52.857C2.91644 53.1412 3.57331 53.2761 4.23376 53.25H8.31188V102.188C8.31188 103.269 8.74154 104.306 9.50634 105.071C10.2711 105.836 11.3084 106.266 12.39 106.266H102.109C103.19 106.266 104.228 105.836 104.992 105.071C105.757 104.306 106.187 103.269 106.187 102.188V53.25H110.265C110.898 53.25 111.523 53.1026 112.089 52.8195C112.655 52.5363 113.148 52.1252 113.528 51.6188C113.929 51.131 114.212 50.5565 114.353 49.9408C114.495 49.3251 114.491 48.6849 114.343 48.0708ZM93.9525 8.39062H98.0306V16.5469H93.9525V8.39062ZM15.4486 24.7031H99.0502L104.841 45.0938H9.65766L15.4486 24.7031ZM61.3275 61.4062V53.25H73.5619V61.4062H61.3275ZM40.9369 61.4062V53.25H53.1713V61.4062H40.9369ZM32.7806 53.25V61.4062H16.4681V53.25H32.7806ZM81.7181 98.1094H69.4838V81.7969H81.7181V98.1094ZM89.8744 98.1094V77.7188C89.8744 76.6372 89.4447 75.5999 88.6799 74.8351C87.9151 74.0703 86.8778 73.6406 85.7963 73.6406H65.4056C64.3241 73.6406 63.2868 74.0703 62.522 74.8351C61.7572 75.5999 61.3275 76.6372 61.3275 77.7188V98.1094H16.4681V69.5625H98.0306V98.1094H89.8744ZM98.0306 61.4062H81.7181V53.25H98.0306V61.4062Z"
                  :fill="selected == index ? 'white' : 'black'" />
              </svg>
              <p class="m-0">Particulier</p>
            </div>
            <div v-else>
              <svg width="90" height="113" viewBox="0 0 90 113" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M78.9834 0.0625H11.0166C8.26884 0.0625 5.63365 1.15403 3.69072 3.09697C1.74778 5.0399 0.65625 7.67509 0.65625 10.4228V108.906C0.65625 109.975 1.08097 111.001 1.83698 111.757C2.59298 112.513 3.61835 112.938 4.6875 112.938H85.3125C86.3817 112.938 87.407 112.513 88.163 111.757C88.919 111.001 89.3438 109.975 89.3438 108.906V10.4228C89.3438 7.67509 88.2522 5.0399 86.3093 3.09697C84.3663 1.15403 81.7312 0.0625 78.9834 0.0625ZM49.0312 60.5312V48.4375H61.125V60.5312H49.0312ZM28.875 60.5312V48.4375H40.9688V60.5312H28.875ZM8.71875 60.5312V48.4375H20.8125V60.5312H8.71875ZM61.125 40.375H49.0312V28.2812H61.125V40.375ZM69.1875 28.2812H81.2812V40.375H69.1875V28.2812ZM40.9688 40.375H28.875V28.2812H40.9688V40.375ZM20.8125 40.375H8.71875V28.2812H20.8125V40.375ZM69.1875 48.4375H81.2812V60.5312H69.1875V48.4375ZM78.9834 8.125C79.5896 8.13536 80.1681 8.38078 80.5968 8.80948C81.0255 9.23818 81.2709 9.81663 81.2812 10.4228V20.2188H69.1875V8.125H78.9834ZM61.125 8.125V20.2188H49.0312V8.125H61.125ZM40.9688 8.125V20.2188H28.875V8.125H40.9688ZM11.0166 8.125H20.8125V20.2188H8.71875V10.4228C8.72911 9.81663 8.97453 9.23818 9.40323 8.80948C9.83193 8.38078 10.4104 8.13536 11.0166 8.125ZM32.9062 104.875V84.7188H57.0938V104.875H32.9062ZM65.1562 104.875V84.7188C66.2254 84.7188 67.2508 84.294 68.0068 83.538C68.7628 82.782 69.1875 81.7567 69.1875 80.6875C69.1875 79.6183 68.7628 78.593 68.0068 77.837C67.2508 77.081 66.2254 76.6562 65.1562 76.6562H24.8438C23.7746 76.6562 22.7492 77.081 21.9932 77.837C21.2372 78.593 20.8125 79.6183 20.8125 80.6875C20.8125 81.7567 21.2372 82.782 21.9932 83.538C22.7492 84.294 23.7746 84.7188 24.8438 84.7188V104.875H8.71875V68.5938H81.2812V104.875H65.1562Z"
                  :fill="selected == index ? 'white' : 'black'" />
              </svg>
              <p class="m-0">Zakelijk</p>
            </div>
          </div>
        </div>
      </div>

    </b-form>
  </b-container>
</template>

<script>
import BackButton from "../../BackButton.vue"
export default {
  name: "Question2",
  components: { BackButton },
  data() {
    return {
      selected: -1,
      values: ['5008', '5011']
    }
  },
  props: {
    step: {
      type: Number,
    },

    secondQuestion: {
      type: String
    }
  },

  mounted() {
    if (this.secondQuestion) {
      this.selected = this.secondQuestion;
    }
  },

  methods: {
    goToNextStep() {
      this.$emit("nextStep", this.step + 1);
    },
    goBack() {
      this.$emit("goBack", this.step - 1);
    },
    updateDiv(index) {
      this.selected = index
      this.$emit("handleChange", 'secondQuestion', index)
      setTimeout(() => {
        this.goToNextStep()
      }, 500);
    },

  }

};
</script>

<style lang="scss" scoped></style>
