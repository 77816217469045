<template>
  <div>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" @click="goBack">
      <path d="M15.1875 9H2.8125" stroke="#FEA700" stroke-width="1.92" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.875 3.9375L2.8125 9L7.875 14.0625" stroke="#FEA700" stroke-width="1.92" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
  </div>
</template>
<script>
export default {
  name: "Back button",
  methods: {
    goBack() {
      this.$emit("back");
    }
  }
}
</script>
<style lang="scss" scoped>
svg {
  cursor: pointer;
}
</style>