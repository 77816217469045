import axios from "axios";

const state = {
  lng: 0,
  lat: 0,
  address: {},
};

const getters = {
  lng: (state) => state.lng,
  lat: (state) => state.lat,
  address: (state) => state.address,
};

const actions = {
  async getLocation({ commit }, payload) {
    try {
      const apiKey = "P6JTU52clKYjZca8";

      const apiUrl = `https://api.pro6pp.nl/v2/autocomplete/nl?authKey=${apiKey}&postalCode=${payload.zip}&streetNumberAndPremise=${payload.house_number}`;

      const { data } = await axios.get(apiUrl);
      const { lat, lng } = data;
      commit("lat", lat);
      commit("lng", lng);
      commit("address", data);
      payload.onSuccess();
    } catch (error) {
      payload.onError();
      commit("lat", 0);
      commit("lng", 0);
      commit("address", {});
    }
  },
};

const mutations = {
  lng(state, payload) {
    state.lng = payload;
  },
  lat(state, payload) {
    state.lat = payload;
  },
  address(state, payload) {
    state.address = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
