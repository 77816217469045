<template>
  <b-container class="main-cont mb-3">
    <b-form class="input__form">
      <BackButton @back="goBack" />
      <p class="mb-0 vraag">Vraag {{ step }} van 6</p>
      <h2 class="title">Wil je zonnepanelen huren of kopen?</h2>
      <div class="row my-4 mx-0">
        <div class="col-6 col-sm-4 p-0" v-for="index in ['5014', '5017']" :key="index">
          <div :class="{ 'input__form-div-selected': selected == index, 'input__form-div': selected != index }"
            @click="updateDiv(index)">
            <div v-if="index == '5014'">
              <svg width="122" height="85" viewBox="0 0 122 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M40.4009 45.3623L32.0047 82M56.8115 45.3623L65.2077 82M11.3961 63.6812H85.4347M93.0675 56.8116C95.3574 53.7585 96.5024 49.5604 96.5024 45.3623C96.5024 34.2947 87.3429 25.1353 76.2753 25.1353C68.6424 25.1353 62.1545 29.3333 58.7197 35.4396M76.2753 12.9227V3M53.7584 22.4638L46.5072 15.2126M108.715 45.3623H118.638M99.1738 22.4638L106.425 15.2126M94.2125 82H3L20.1739 45.3623H77.0386L94.2125 82Z"
                  :stroke="selected == index ? 'white' : 'black'" stroke-width="5.72464" stroke-miterlimit="10"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p class="m-0">Kopen</p>
            </div>
            <div v-else>
              <svg width="122" height="85" viewBox="0 0 122 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M40.4009 45.3623L32.0047 82M56.8115 45.3623L65.2077 82M11.3961 63.6812H85.4347M93.0675 56.8116C95.3574 53.7585 96.5024 49.5604 96.5024 45.3623C96.5024 34.2947 87.3429 25.1353 76.2753 25.1353C68.6424 25.1353 62.1545 29.3333 58.7197 35.4396M76.2753 12.9227V3M53.7584 22.4638L46.5072 15.2126M108.715 45.3623H118.638M99.1738 22.4638L106.425 15.2126M94.2125 82H3L20.1739 45.3623H77.0386L94.2125 82Z"
                  :stroke="selected == index ? 'white' : 'black'" stroke-width="5.72464" stroke-miterlimit="10"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p class="m-0">Huren</p>
            </div>
          </div>
        </div>
      </div>

    </b-form>
  </b-container>
</template>

<script>
import BackButton from "../../BackButton.vue"
export default {
  name: "Question3",
  components: { BackButton },
  data() {
    return {
      selected: -1,

    }
  },
  props: {
    step: {
      type: Number,
    },
    thirdQuestion: {
      type: String
    }
  },
  mounted() {
    if (this.thirdQuestion) {
      this.selected = this.thirdQuestion;
    }
  },

  methods: {
    goToNextStep() {
      this.$emit("nextStep", this.step + 1);
    },
    goBack() {
      this.$emit("goBack", this.step - 1);
    },
    updateDiv(index) {
      this.selected = index
      this.$emit("handleChange", 'thirdQuestion', index)
      setTimeout(() => {
        this.goToNextStep()
      }, 500);
    }
  }

};
</script>

<style lang="scss" scoped></style>
