<template>
  <div class='Stijgende'>
    <b-container>
      <b-row>

        <b-col class="col-12 col-md-5 Stijgende__left">
          <img src="../assets/Stijgende.png" />
        </b-col>
        <b-col class="col-12 col-md-7 text-start Stijgende__right px-4 px-md-1">
          <h1 class="heading">Stijgende opbrengsten</h1>
          <p class="paragraph">
            De ontwikkeling van zonnepanelen is nog steeds in volle gang. De laatste generatie panelen is ontworpen om
            20 tot 30 jaar mee te gaan. Daarnaast zijn deze inmiddels tot wel 6 keer efficiënter in het opslaan van
            energie.
          </p>
          <b-button class="cta_button" @click="toggleForm">Check mijn mogelijkheden <span class="mx-3"><svg width="9"
                height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.469757 1.53023L5.93951 6.99998L0.469757 12.4697L1.53026 13.5302L8.06051 6.99998L1.53026 0.469727L0.469757 1.53023Z"
                  fill="white" />
              </svg>
            </span></b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Stijgende',
  props: {
    showQuestions: Boolean,
  },
  methods: {
    toggleForm() {
      this.$emit("update", !this.showQuestions);
    },
  },
}
</script>

<style lang="scss" scoped>
.Stijgende {
  padding: 3rem 0;
  background: #F3F3F3;

  &__right {
    .heading {
      color: #171734;
      font-size: 50px;
      font-weight: 700;
      line-height: 64px;
      letter-spacing: 0px;
      text-align: left;
    }

    .paragraph {
      color: #171734;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0px;
      text-align: left;
    }

    .cta_button {
      font-family: "Montserrat", sans-serif;
      background: #FEA700;
      padding: 20px 40px;
      font-weight: 600;
      border-radius: 100px;
      gap: 14px;
      border: none;

      &:active {
        background: #FEA700;
      }
    }
  }

  &__left {
    img {
      width: 80%;
    }
  }

  @media (max-width:992px) {
    &__left {
      img {
        width: 94%;
        margin-bottom: 1rem;
      }
    }
  }

  @media (max-width: 575px) {
    &__left {
      .heading {
        font-size: 30px;
        line-height: 42px;
      }

    }

    .cta_button {
      width: 100%;
      padding: 19px;
    }

  }

  @media (max-width: 340px) {
    &__right {
      .heading {
        font-size: 25px;
      }
    }

  }
}
</style>