<template>
  <b-container class="py-4 my-5 parent_container">
    <b-row v-if="step < 7">
      <b-col class="col-md-8">
        <div class="form">
          <div class="progress">
            <div v-for="index in 6" :key="index" class="step" :class="{ completed: step >= index }"></div>
          </div>

          <div v-if="step === 1">
            <Question1 :firstQuestion="stepsDetail.firstQuestion" :step="step" :setAddress="setAddress"
              @nextStep="updateStep" @goBack="decreaseStep" />
          </div>
          <div v-else-if="step == 2">
            <Question2 :step="step" @nextStep="updateStep" :secondQuestion="stepsDetail.secondQuestion"
              @handleChange="changePayload" @goBack="decreaseStep" />
          </div>
          <div v-else-if="step == 3">
            <Question3 :step="step" @nextStep="updateStep" :thirdQuestion="stepsDetail.thirdQuestion"
              @handleChange="changePayload" @goBack="decreaseStep" />
          </div>
          <div v-else-if="step == 4">
            <Question4 :step="step" @nextStep="updateStep" :fourthQuestion="stepsDetail.fourthQuestion"
              @handleChange="changePayload" @goBack="decreaseStep" />
          </div>
          <div v-else-if="step == 5">
            <Question5 @handleChange="changePayload" :step="step" :fifthQuestion="stepsDetail.fifthQuestion"
              @nextStep="updateStep" @goBack="decreaseStep" />
          </div>
          <div v-else-if="step == 6">
            <Question6 @handleChange="changePayload" :step="step" :sixthQuestion="stepsDetail.sixthQuestion"
              @nextStep="updateStep" @goBack="decreaseStep" />
          </div>
          <div class="d-flex justify-content-between">
            <div class="mx-3 cursor-pointer" @click="previousPage"
              v-if="step == 1 && stepsDetail.firstQuestion.show == false">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.1875 9H2.8125" stroke="#FEA700" stroke-width="1.92" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M7.875 3.9375L2.8125 9L7.875 14.0625" stroke="#FEA700" stroke-width="1.92"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p class="input__form-back_label d-inline-block mx-2">Adres aanpassen</p>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col class="col-md-8">
        <div class="form">
          <div v-if="step == 7">
            <QuestionAnalyze :step="step" @nextStep="updateStep" />
          </div>
          <div v-else-if="step == 8">
            <ResultForm :details="stepsDetail" :step="step" @nextStep="updateStep" @setAddress="correctAddress" />
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Question1 from "./Question1.vue"
import Question2 from "./Question2.vue"
import Question3 from "./Question3.vue"
import Question4 from "./Question4.vue"
import Question5 from "./Question5.vue"
import Question6 from "./Question6.vue";
import QuestionAnalyze from "./QuestionAnalyze.vue";
import ResultForm from "./ResultForm.vue";
export default {
  name: "Questions",
  components: {
    Question1,
    Question2,
    Question3,
    Question4,
    Question5,
    Question6,
    QuestionAnalyze,
    ResultForm
  },
  data() {
    return {
      step: 1,
      setAddress: false,
      stepsDetail: {
        firstQuestion: {
          zip: "",
          house_number: "",
          show: true,
        },
        secondQuestion: "",
        thirdQuestion: "",
        fourthQuestion: "",
        fifthQuestion: "",
        sixthQuestion: "",
        form: {
          firstName: "",
          lastName: "",
          email: "",
          phone_number: "",
          leadResponse: false
        }
      }
    }
  },
  methods: {
    updateStep(newStep) {
      this.step = newStep;
    },
    decreaseStep(newStep) {
      console.log({ newStep })
      if (newStep < 1) {
        location.reload()
      } else {
        this.step = newStep;
      }
    },
    previousPage() {
      this.stepsDetail.firstQuestion.show = true
      this.step = 1
    },
    changePayload(key, value) {
      this.stepsDetail[key] = value;
    },
    correctAddress(value) {
      this.step = 1,
        this.stepsDetail.firstQuestion.show = true
      this.setAddress = true;
      setTimeout(() => {
        this.setAddress = false;
      }, 5000);
    }
  },

};
</script>

<style lang="scss">
.parent_container {
  min-height: 64vh;
}

.form {
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  margin-bottom: 20px;
  background-color: #ffffff !important;
}

.step {
  width: 100%;
  height: 7px;
  border: 2px solid #ccc;
  margin-left: 10px !important;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 12px;

  &.completed {
    background-color: #FEA700;
    border-color: #FEA700;
  }
}
</style>
