<template>
  <b-container class="main-cont mb-3">
    <b-form class="input__form">
      <BackButton @back="goBack" />
      <p class="mb-0 vraag">Vraag {{ step }} van 6</p>
      <h2 class="title">Soort dak?</h2>
      <div class="row my-4 mx-0">
        <div
          class="col-6 col-sm-4 p-0"
          v-for="index in ['5020', '5023']"
          :key="index"
        >
          <div
            :class="{
              'input__form-div-selected': selected == index,
              'input__form-div': selected != index,
            }"
            @click="updateDiv(index)"
          >
            <div v-if="index == '5020'">
              <svg
                width="79"
                height="68"
                viewBox="0 0 79 68"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M39.0908 0L0 35.1831H10.6611V67.1665H14.2148H67.5205V35.1831H78.1816L39.0908 0ZM39.0908 9.56448L60.4131 28.7559V60.0591H53.3057V38.7368H42.6445V60.0591H17.7686V28.7559L39.0908 9.56448ZM24.876 38.7368V49.398H35.5371V38.7368H24.876Z"
                  :fill="selected == index ? 'white' : 'black'"
                />
              </svg>
              <p class="m-0">Schuin</p>
            </div>
            <div v-else>
              <svg
                width="70"
                height="56"
                viewBox="0 0 70 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35.058 8.99567L0.181641 8.99567V56H5.22834H69.9343V8.99567H61.7626H35.058ZM35.058 8.99567H61.7626V47.3024H53.065V21.2097H40.0187V47.3024H8.3534V8.99567H35.058ZM18.2747 21.2097V34.256H31.3211V21.2097H18.2747Z"
                  :fill="selected == index ? 'white' : 'black'"
                />
                <rect
                  x="0.181641"
                  y="0.429565"
                  width="69.7527"
                  height="8.56612"
                  :fill="selected == index ? 'white' : 'black'"
                />
              </svg>
              <p class="m-0">Plat</p>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </b-container>
</template>

<script>
import BackButton from "../../BackButton.vue";
export default {
  name: "Question4",
  components: { BackButton },
  data() {
    return {
      selected: -1,
    };
  },
  props: {
    step: {
      type: Number,
    },
    fourthQuestion: {
      type: String,
    },
  },
  mounted() {
    if (this.fourthQuestion) {
      this.selected = this.fourthQuestion;
    }
  },

  methods: {
    goToNextStep() {
      this.$emit("nextStep", this.step + 1);
    },
    goBack() {
      this.$emit("goBack", this.step - 1);
    },
    updateDiv(index) {
      this.selected = index;
      this.$emit("handleChange", "fourthQuestion", index);
      setTimeout(() => {
        this.goToNextStep();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
