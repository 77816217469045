<template>
  <b-container class="main-cont mb-3">
    <b-form class="input__form">
      <h2 class="title mb-3">Duurzame Huiseigenaren verwerkt nu jouw gegevens…</h2>
      <b-row v-for="(item, index) of points" :key="index" v-if="indexes.includes(index)"
        class="mb-2 d-flex justify-content-start align-items-start mx-1 mx-sm-0">
        <b-col class="col-1 p-0">
          <svg class="mr-2" width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5_214)">
              <path
                d="M13.1593 7.01524L8.15093 11.9254L5.84433 9.66403C5.54793 9.37344 5.06533 9.37344 4.76893 9.66403C4.47253 9.95462 4.47253 10.4278 4.76893 10.7183L7.61133 13.505C7.75953 13.6503 7.95333 13.7248 8.14713 13.7248C8.34093 13.7248 8.53473 13.6503 8.68293 13.505L14.2271 8.06952C14.5235 7.77893 14.5235 7.30579 14.2271 7.01524C13.9307 6.72466 13.4557 6.72466 13.1593 7.01524Z"
                fill="#1CC500" />
              <path
                d="M9.49992 0.573914C4.25216 0.573914 0 4.74271 0 9.88758C0 15.0324 4.25216 19.2012 9.49992 19.2012C14.7477 19.2012 18.9998 15.0324 18.9998 9.88758C18.9998 4.74271 14.7477 0.573914 9.49992 0.573914ZM9.49992 17.711C5.09956 17.711 1.51999 14.2017 1.51999 9.88758C1.51999 5.5735 5.09956 2.0641 9.49992 2.0641C13.9003 2.0641 17.4798 5.5735 17.4798 9.88758C17.4798 14.2017 13.9003 17.711 9.49992 17.711Z"
                fill="#1CC500" />
            </g>
            <defs>
              <clipPath id="clip0_5_214">
                <rect width="19" height="19" fill="white" transform="translate(0 0.5)" />
              </clipPath>
            </defs>
          </svg>
        </b-col>
        <b-col class="col-11 p-0">
          <p class="mb-0 point"><strong>{{ item }}</strong></p>
        </b-col>
      </b-row>
      <!-- <div class="text-start mb-1 d-flex justify-content-start align-items-start" v-for="(item, index) of points"
       >
       
      </div> -->
      <p class="input__form-label mt-3" v-if="indexes.includes(3)">
        We sturen jou nu door om de offertes en adviezen op maat te ontvangen!
      </p>
      <div class="text-center pt-3">
        <b-spinner variant="warning"></b-spinner>
      </div>
    </b-form>
  </b-container>
</template>
<script>
export default {
  name: "Analyze",
  props: {
    step: {
      type: Number,
    },
  },
  data() {
    return {
      points: [
        "Jouw woning is geschikt en jij kunt veel besparen op energiekosten",
        "Wij hebben beschikbare installateurs gevonden die voldoen aan jouw wensen",
        "Jij kunt nu 40% Extra voordeel ontvangen"
      ],
      indexes: []
    };
  },
  mounted() {
    let seconds = 0;

    const intervalId = setInterval(() => {
      this.indexes.push(seconds);
      seconds++;

      if (seconds === 5) {
        clearInterval(intervalId);
        this.$emit("nextStep", this.step + 1);
      }
    }, 2000);
  }
}
</script>
<style lang="scss" scoped>
.input__form {
  min-height: 50vh !important;
}

.title {
  font-size: 30px;
}

@media (max-width:450px) {
  .point {
    font-size: 14px;
  }
}

@media (max-width:425px) {
  .title {
    font-size: 20px !important;
    line-height: 25px !important;
  }

}
</style>
