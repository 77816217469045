<template>
  <div class='Duurzame'>
    <b-container>
      <b-row class="d-flex flex-column-reverse flex-md-row">
        <b-col class="col-12 col-md-7 text-start Duurzame__left px-4 px-md-1">
          <h1 class="heading">Duurzame Huiseigenaren</h1>
          <p class="paragraph">
            Duurzame Huiseigenaren inspireert en begeleidt huiseigenaren bij het verduurzamen van jouw huis! Goed voor
            jouw portemonnee, en nog beter voor het milieu.
          </p>
          <p class="paragraph">
            <span>Bij Duurzame Huiseigenaren geloven we in een groenere toekomst, en we zijn toegewijd aan het
              helpen en
              ondersteunen van huiseigenaren bij het realiseren van duurzame woonoplossingen.</span> Ons team
            van experts biedt
            deskundig advies en begeleiding gedurende het aankooptraject.
          </p>
          <p class="paragraph">
            Vul ons formulier in en wij nemen zo snel als mogelijk contact met je op. Ontvang meerdere vrijblijvende
            offertes van betrouwbare partijen uit de regio, zodat je de juiste match kan vinden. <span> Zo weet
              je zeker dat je
              een goede prijs betaalt en vergroot je de kans dat je een erkende partij vindt waar je een goed gevoel
              bij
              hebt.</span>
          </p>
        </b-col>
        <b-col class="col-12 col-md-5 Duurzame__right">
          <img src="../assets/Duurzame.png" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Duurzame',
  data() {
    return {
      cardPoints: []
    };
  },
}
</script>

<style lang="scss" scoped>
.Duurzame {
  padding: 3rem 0;

  &__left {
    .heading {
      color: #171734;
      font-size: 50px;
      font-weight: 700;
      line-height: 64px;
      letter-spacing: 0px;
      text-align: left;
    }

    .paragraph {
      color: #171734;
      font-size: 16px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0px;
      text-align: left;

      span {
        font-weight: 700;
      }
    }
  }

  &__right {
    img {
      width: 80%;
    }
  }

  @media (max-width:992px) {
    &__right {
      img {
        width: 94%;
        margin-bottom: 1rem;
      }
    }
  }

  @media (max-width: 575px) {
    &__left {

      .heading {
        font-size: 30px;
        line-height: 42px;
      }

      .heading_2 {
        font-size: 25px;
        margin-top: 2rem;
      }
    }

  }

  @media (max-width: 340px) {
    &__left {
      .heading {
        font-size: 25px;
      }
    }

  }
}
</style>