<template>
  <div class="guide">
    <b-container class="py-5 " :class="{ 'd-none': showSecondForm, 'd-md-block': showSecondForm }">
      <h1 class="guide__heading">
        Hoe werkt het?
      </h1>
      <b-row>
        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-start text-white" v-for="(item, index) of guidePoints"
          :key="index">
          <div class="d-flex">
            <h1
              class="mb-1 d-flex justify-content-between align-items-center justify-content-md-start w-100 guide__point-count">
              {{
      item.count }}. <span v-if="index < 2" class="mx-0 d-none d-md-block mx-md-3"><svg width="18" height="18"
                  viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.62634 1.707L13.9193 8H0.333344V10H13.9193L7.62634 16.293L9.04034 17.707L17.7473 9L9.04034 0.292999L7.62634 1.707Z"
                    fill="black" />
                </svg>
              </span>
              <span v-if="index < 2" class=" d-block d-md-none"><svg width="18" height="18" viewBox="0 0 18 18"
                  fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.293 7.293L9.99997 13.586L9.99997 -3.36884e-07L7.99997 -4.24306e-07L7.99997 13.586L1.70697 7.293L0.292968 8.707L8.99997 17.414L17.707 8.707L16.293 7.293Z"
                    fill="black" />
                </svg>

              </span>
            </h1>
          </div>
          <p class="mb-1 guide__point-title">
            {{ item.heading }}
          </p>
          <p class="underline guide__point-description text-justified">
            {{ item.desc }}
          </p>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "GuideSection",
  data() {
    return {
      guidePoints: [
        {
          count: "01",
          heading: "Mijn Mogelijkheden",
          desc: "Start de Gratis Check en wij checken alle opties en mogelijkheden voor jouw woning"
        },
        {
          count: "02",
          heading: "Match",
          desc: "Op basis van jouw wensen vinden wij de beste en beschikbare lokale installateurs"
        },
        {
          count: "03",
          heading: "Gratis offertes",
          desc: "Ontvang 5 superscherpe offertes van erkende en lokale installateurs met 40% extra voordeel. 100% gratis en vrijblijvend."
        },
      ]
    };
  },
  props: {
    showSecondForm: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.guide {
  background-color: #FEA700;

  &__heading {
    color: #333333;
    font-size: 45px;
    font-weight: 600;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
  }

  &__point-title,
  &__point-count {
    font-size: 32px;
    font-weight: 600;
  }

  @media (max-width: 575px) {
    &__heading {
      font-size: 30px;
      line-height: 42px;
    }
  }
}
</style>
