<template>
  <b-container class="main-cont mb-3">
    <b-form class="input__form">
      <BackButton @back="goBack" />
      <p class="mb-0 vraag">Vraag {{ step }} van 6</p>
      <h2 class="title">Grootte van het huishouden?</h2>
      <div class="row my-4 mx-0">
        <b-form-group>
          <b-form-radio v-for="(item, index) of options" v-model="selected" :key="index" :name="'dateOption'"
            :value="item.value" @change="updateDiv(item.value)">
            {{ item.title }}
          </b-form-radio>
        </b-form-group>
      </div>
    </b-form>
  </b-container>
</template>

<script>
import BackButton from "../../BackButton.vue"
export default {
  name: "Question6",
  components: { BackButton },
  data() {
    return {
      selected: -1,
      options: [
        { title: "1 persoon (+- 2.300 kwh)", value: '5041' },
        { title: "2 personen (+- 3.400 kwh)", value: '5044' },
        { title: "3 personen (+- 4.100 kwh)", value: '5047' },
        { title: "4 personen (+- 4.600 kwh)", value: '5050' },
        { title: "Meer dan 4 personen", value: '5053' },
      ],
    };
  },
  props: {
    step: {
      type: Number,
    },
    sixthQuestion:
      { type: String }
  },
  mounted() {
    if (this.sixthQuestion) {
      this.selected = this.sixthQuestion;
    }
  },
  methods: {
    goToNextStep() {
      this.$emit("nextStep", this.step + 1);
    },
    goBack() {
      this.$emit("goBack", this.step - 1);
    },
    updateDiv(value) {
      this.$emit("handleChange", 'sixthQuestion', value)
      setTimeout(() => {
        this.goToNextStep();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
