import Repository from "./Repository";
const LEADS = "/leads";

import qs from "qs";

export default {
  postLead(payload) {
    return Repository.post(`${LEADS}`, payload);
    // return Repository.post(`${LEADS}`, qs.stringify(payload));
  },
};
