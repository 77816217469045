<template>
  <div class="home">
    <HeroSection :showQuestions="showQuestions" @update="toggleQuestion" />
    <div v-if="!showQuestions">
      <GuideSection />
      <FinancialAdvice />
      <Stijgende :showQuestions="showQuestions" @update="toggleQuestion" />
      <Duurzame />
    </div>
    <Footer />

  </div>
</template>

<script>
// @ is an alias to /src
import HeroSection from '@/components/HeroSection.vue'
import GuideSection from '../components/GuideSection.vue';
import FinancialAdvice from '../components/FinancialAdvice.vue';
import Stijgende from '../components/Stijgende.vue';
import Duurzame from '../components/Duurzame.vue';
import Footer from '@/components/Footer.vue'

export default {
  name: "Home",
  data() {
    return {
      showQuestions: false
    }
  },
  components: {
    HeroSection,
    GuideSection,
    FinancialAdvice,
    Stijgende,
    Duurzame,
    Footer
  },
  methods: {
    toggleQuestion() {
      this.showQuestions = true
    }
  }
}
</script>
