<template>
  <b-container class="main-cont mb-3">
    <b-form class="input__form">
      <BackButton @back="goBack" />
      <p class="mb-0 vraag">Vraag {{ step }} van 6</p>
      <h2 class="title">Gewenste uitvoerdatum?</h2>
      <div class="row my-4 mx-0">
        <b-form-group>
          <b-form-radio v-for="(item, index) of options" v-model="selected" :key="index" :name="'dateOption'"
            :value="item.value" @change="updateDiv(item.value)">
            {{ item.title }}
          </b-form-radio>
        </b-form-group>
      </div>
    </b-form>
  </b-container>
</template>

<script>
import BackButton from "../../BackButton.vue"
export default {
  name: "Question5",
  components: { BackButton },
  data() {
    return {
      selected: -1,
      options: [
        { title: "Zo snel mogelijk", value: '5026' },
        { title: "Binnen 3 maanden", value: '5029' },
        { title: "Binnen 3 tot 6 maanden", value: '5032' },
        { title: "Binnen 6 tot 12 maanden", value: '5035' },
        { title: "In overleg", value: '5038' },
      ],
    };
  },
  props: {
    step: {
      type: Number,
    },
    fifthQuestion: {
      type: String
    }
  },
  mounted() {
    if (this.fifthQuestion) {
      this.selected = this.fifthQuestion;
    }
  },
  methods: {
    goToNextStep() {
      this.$emit("nextStep", this.step + 1);
    },
    goBack() {
      this.$emit("goBack", this.step - 1);
    },
    updateDiv(value) {
      this.$emit("handleChange", 'fifthQuestion', value)
      setTimeout(() => {
        this.goToNextStep();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
