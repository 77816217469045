<template>
  <b-container class="py-4">
    <b-row class="my-2 my-sm-5">
      <b-Col class="col-lg-6 col-md-9">
        <div class="form px-0">
          <div class="form__top">
            <h1 class="form__heading">
              Zonnepanelen <br />
              <span>
                Nu ruim 50% <br />
                goedkoper!
              </span>
            </h1>
            <p class="text-start form__sub_heading">
              Profiteer en start nu met besparen én verdienen!
              <br class="d-none d-sm-block" />
              Met onze unieke
              <span class="gratis"> <strong> Gratis Woningcheck </strong> </span
              >ontdek jij:
            </p>
            <div
              class="text-start mb-1 points__div d-none d-sm-block"
              v-for="(item, index) of points"
              :key="index"
            >
              <p class="mb-0">
                <span>
                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_5_214)">
                      <path
                        d="M13.1593 7.01524L8.15093 11.9254L5.84433 9.66403C5.54793 9.37344 5.06533 9.37344 4.76893 9.66403C4.47253 9.95462 4.47253 10.4278 4.76893 10.7183L7.61133 13.505C7.75953 13.6503 7.95333 13.7248 8.14713 13.7248C8.34093 13.7248 8.53473 13.6503 8.68293 13.505L14.2271 8.06952C14.5235 7.77893 14.5235 7.30579 14.2271 7.01524C13.9307 6.72466 13.4557 6.72466 13.1593 7.01524Z"
                        fill="#1CC500"
                      />
                      <path
                        d="M9.49992 0.573914C4.25216 0.573914 0 4.74271 0 9.88758C0 15.0324 4.25216 19.2012 9.49992 19.2012C14.7477 19.2012 18.9998 15.0324 18.9998 9.88758C18.9998 4.74271 14.7477 0.573914 9.49992 0.573914ZM9.49992 17.711C5.09956 17.711 1.51999 14.2017 1.51999 9.88758C1.51999 5.5735 5.09956 2.0641 9.49992 2.0641C13.9003 2.0641 17.4798 5.5735 17.4798 9.88758C17.4798 14.2017 13.9003 17.711 9.49992 17.711Z"
                        fill="#1CC500"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5_214">
                        <rect
                          width="19"
                          height="19"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg> </span
                ><strong class="mx-2">{{ item }}</strong>
              </p>
            </div>
            <div
              class="text-start mb-1 points__div d-block d-sm-none"
              v-for="(item, index) of mobilePoints"
              :key="index"
            >
              <p class="mb-0">
                <span>
                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_5_214)">
                      <path
                        d="M13.1593 7.01524L8.15093 11.9254L5.84433 9.66403C5.54793 9.37344 5.06533 9.37344 4.76893 9.66403C4.47253 9.95462 4.47253 10.4278 4.76893 10.7183L7.61133 13.505C7.75953 13.6503 7.95333 13.7248 8.14713 13.7248C8.34093 13.7248 8.53473 13.6503 8.68293 13.505L14.2271 8.06952C14.5235 7.77893 14.5235 7.30579 14.2271 7.01524C13.9307 6.72466 13.4557 6.72466 13.1593 7.01524Z"
                        fill="#1CC500"
                      />
                      <path
                        d="M9.49992 0.573914C4.25216 0.573914 0 4.74271 0 9.88758C0 15.0324 4.25216 19.2012 9.49992 19.2012C14.7477 19.2012 18.9998 15.0324 18.9998 9.88758C18.9998 4.74271 14.7477 0.573914 9.49992 0.573914ZM9.49992 17.711C5.09956 17.711 1.51999 14.2017 1.51999 9.88758C1.51999 5.5735 5.09956 2.0641 9.49992 2.0641C13.9003 2.0641 17.4798 5.5735 17.4798 9.88758C17.4798 14.2017 13.9003 17.711 9.49992 17.711Z"
                        fill="#1CC500"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5_214">
                        <rect
                          width="19"
                          height="19"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg> </span
                ><strong class="mx-2">{{ item }}</strong>
              </p>
            </div>
          </div>
          <img src="../../assets/offer.png" class="offer_img" />
          <div class="text-start px-4 py-3 profit__div">
            <p class="mb-0">
              Ontvang gratis & vrijblijvend 5 offertes + deskundig advies én
              profiteer van <strong> 40% Extra Voordeel. </strong>
            </p>
          </div>
          <div class="pb-0 pb-sm-4 px-4 pt-4 text-center text-sm-start">
            <b-button
              class="btnForm mb-2 d-flex justify-content-center align-items-center"
              @click="showSecondForm"
            >
              <span> Start Gratis Woningcheck </span>
              <span class="mx-2">
                <svg
                  width="9"
                  height="14"
                  viewBox="0 0 9 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.469757 1.53029L5.93951 7.00004L0.469757 12.4698L1.53026 13.5303L8.06051 7.00004L1.53026 0.469788L0.469757 1.53029Z"
                    fill="white"
                  />
                </svg>
              </span>
            </b-button>
            <p class="mb-0"><strong> In 1 minuut klaar! </strong></p>
          </div>
        </div>
      </b-Col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Form",
  data() {
    return {
      points: [
        "De beste opties en mogelijkheden voor jouw woning",
        "De beste en beschikbare lokale installateurs",
        "Jouw Extra Voordeel",
      ],
      mobilePoints: [
        "De beste opties voor jouw woning",
        "De beste lokale installateurs",
        "Jouw Extra Voordeel",
      ],
    };
  },
  methods: {
    showSecondForm() {
      this.$emit("show-second-form");
    },
  },
};
</script>

<style lang="scss">
.form {
  position: relative;
  background-color: #ffffff;
  border-radius: 1rem !important;

  &__top {
    padding: 1.5rem !important;

    @media (max-width: 525px) {
      padding: 0 1rem !important;
      padding-bottom: 1rem !important;
    }
  }

  .offer_img {
    position: absolute;
    top: 50%;
    width: 30%;
    left: 110%;
  }

  &__heading {
    font-size: 45px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0.18460631370544434px;
    text-align: left;
    color: #333333;

    span {
      color: #fea700;
    }
  }

  &__sub_heading {
    color: #171734;

    .gratis {
      color: #fea700;
    }
  }

  .points__div {
    color: #171734;
  }

  .profit__div {
    background: #eeeeee;
  }

  @media (min-width: 1699px) {
    .offer_img {
      position: absolute;
      top: 0%;
      width: 30%;
      left: 81%;
    }
  }

  @media (max-width: 1199px) {
    .offer_img {
      top: 11%;
      left: 76%;
    }
  }

  @media (max-width: 992px) {
    .offer_img {
      width: 28%;
      top: 13%;
    }
  }

  @media (max-width: 575px) {
    .offer_img {
      width: 28%;
      top: 15%;
      left: 80%;
    }
  }

  @media (max-width: 525px) {
    .offer_img {
      top: 8%;
      width: 32%;
    }
  }

  @media (max-width: 992px) {
    &__heading {
      font-size: 55px;
    }
  }

  @media (max-width: 768px) {
    &__heading {
      font-size: 55px;
    }
  }

  @media (max-width: 625px) {
    &__heading {
      font-size: 55px;
    }
  }

  @media (max-width: 525px) {
    &__heading {
      font-size: 45px;
    }
  }

  @media (max-width: 475px) {
    &__heading {
      font-size: 40px;
    }
  }

  @media (max-width: 425px) {
    &__heading {
      font-size: 35px;
      line-height: 38px;
    }

    .points__div {
      p {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 375px) {
    &__heading {
      font-size: 29px;
      line-height: 34px;
    }

    .points__div {
      p {
        font-size: 12px;
      }
    }

    p {
      font-size: 14px;
    }
  }

  @media (max-width: 350px) {
    .points__div {
      p {
        font-size: 10px;
      }

      svg {
        width: 14px;
      }
    }

    p {
      font-size: 11px;
    }
  }
}

.btnForm {
  font-family: "Montserrat", sans-serif;
  background: #fea700 !important;
  border: none !important;
  color: #ffffff;
  padding: 20px 40px !important;
  border-radius: 100px !important;
  gap: 10px;
  font-weight: 600;

  &:active {
    background: #fea700 !important;
    border: none !important;
  }

  @media (max-width: 525px) {
    padding: 10px 32px !important;
    gap: 9px;
  }
}
</style>
